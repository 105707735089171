<template>
  <div class="recode">
    <div class="list-top">
       <div 
        :class="['list-item', {active: current == index}]"
        v-for="(item, index) in tabs"
        @click="chooseOrder(item.key, index)"
        :key="index">
        {{item.name}}
      </div>  
    </div>

    <div class="list-cont">
      <yd-infinitescroll :callback="getList" ref="infinitescrollDemo">
        <div slot="list">
          <div v-for="item in orderList" :key="item.id" class="list-layout" @click.stop="orderDetail(item)">
            <div class="overall-layout">
              <div class="layout-top">
                <span class="time">{{item.create_time  | formatDateTime}}</span>
                <div class="status" v-if="getOrderState(item.order_status)=='待付款'&&type==3&&item.is_password==0">待提交</div>
                <div class="status" v-else-if="getOrderState(item.order_status)=='待付款'&&type==3&&item.is_password==1">待审批</div>
                <div class="status" v-else>{{getOrderState(item.order_status)}}</div>
                <!-- <span class="points">-{{item.integral}}积分</span> -->
              </div>

              <div class="layout-bottom">
                <div class="img">
                  <img :src="item.order_items[0].base_pic" alt="">
                </div>
                <div class="img-txt">
                  <p class="name">{{item.order_items[0].item_name}}</p>
                  <p class="number">&times;{{item.order_items[0].number}}</p>
                </div>
                <div class="use-point">
                  -{{item.integral}}积分
                </div>
              </div>

              <div class="order-btn">
              
                <div 
                  v-if="(getOrderKey(item.order_status) === 'PAY'||(item.status===0&&getOrderKey(item.order_status) !==  'SALE_DEAL'))&&type!==3"
                  @click.stop="selectPay(item)"
                  class="confirm common">
                    立即支付
                </div>

                <div
                  v-if="getOrderKey(item.order_status) === 'PENDING' || getOrderKey(item.order_status) === 'PAY'||(item.status===0&&getOrderKey(item.order_status) !==  'SALE_DEAL')"
                  @click.stop="cancelOrder(item)"
                  class="cancel common">
                    取消订单
                </div>

                  <div
                    v-if="getOrderKey(item.order_status) === 'TAKE' "
                    @click.stop="confirmReceipt(item)"
                    class="confirm common">
                    确认收货
                  </div>
              </div>
            </div>
          </div>
        </div>

        <span slot="doneTip" v-if="orderList.length > 0">- 没有更多了 -</span>
      </yd-infinitescroll>  
    </div>
    <!--安全键盘-->
    <yd-keyboard v-model="show_keyboard" :callback="checkPwd" disorder ref="keyboard" title></yd-keyboard>
  
    <!-- 空视图 -->
      <empty :icon="emptyIcon" v-if="orderList.length ===0 && emptyStatus"></empty>
  </div>
</template>

<script>
import { payOrder,getOrderList, getAfterSaleList, confirmReceipt} from "@/services/orderApi.js";
import {orderMixin} from "@/mixins/orderMixin.js";
import {mapState} from 'vuex';
import md5 from "js-md5";
import empty from "@/components/empty/Empty";
import { baseUrl } from "@/utils/env";

export default {
  name: 'recode',
  mixins: [orderMixin],
  data() {
    return {
      tabs: [
        {
          name: '全部',
          key: 'ALL'
        },
         {
          name: '待付款',
          key: 'PAY'
        }
      ],
      currentKey: this.$route.query.key.split('?')[0],
      current: Number,
      page: 1,
      listRows: 10,
      orderList: [],
      show_keyboard: false, //支付密码键盘
      total: '',
      emptyStatus: false  //  空数据占位图状态
    }
  },
  components: {
    empty
  },
  computed: {
    ...mapState({
      type: state => state.businessInfo.type,//1现金商城，2积分商品，3内采商城
      integralRate: state => state.config.integral_rate, // 积分比例
      pay_password: state => !!state.userInfo.pay_password //是否设置支付密码
    })
  },
  created() {
    this.stateList = this.getTabList(this.currentKey);
    this.getList()
    if (this.currentKey === 'ALL') {
      this.current = 0
    } else {
      this.current = 1
    }
  },
  methods: {

     //  确认收货
    async confirmReceipt(item) {
      let id = item.id
      try {
        if (id) {
          const res = await confirmReceipt(id);
          if (res.code === 0) {
            const data = res.data;
            console.log(data);
            this.$dialog.toast({
              mes: "确认收货成功"
            });
            this.$router.push({
              name: "confirm_success"
            });
          } else {
            this.$dialog.toast({
              mes: res.msg
            });
          }
        }
      } catch (e) {}
    },
    async getList() {
      try {
        if (this.orderList.length === this.total) {
          this.$refs.infinitescrollDemo.$emit( 'ydui.infinitescroll.loadedDone');
          return
        }
        let order_status = this.getOrderId(this.currentKey);
        let data, list;
        data = await getOrderList(this.page, this.listRows, order_status, this.type);
        list = data.data.data;
        this.is_request = 1;
        if (data.code === 0) {
          this.orderList = [...this.orderList, ...list];
          this.emptyStatus = true
          this.total = data.data.total
          if (this.orderList.length === parseInt(data.data.total) && this.orderList.length > 0) {
            // 所有数据加载完毕
            this.$refs.infinitescrollDemo.$emit(
              "ydui.infinitescroll.loadedDone"
            );
            return;
          }
          this.$refs.infinitescrollDemo.$emit("ydui.infinitescroll.finishLoad");
          this.page++;
        }
      } catch (err) {
        throw err;
      }
    },
    chooseOrder(key, index) {
      if (index === this.current) return;
      this.$router.replace({
        path: "/recode",
        query: {
          key: key
        }
      });
      this.current = index
      this.currentKey = key;
      this.orderList = [];
      this.page = 0;
      this.is_request = 0;
      this.total = ''
      this.emptyStatus = false
      this.$refs.infinitescrollDemo.$emit("ydui.infinitescroll.reInit");
      this.getList()
    },

     // 取消订单
    cancelOrder(item) {
      this.$router.push({
        path: "/exchange_detail",
        query: {
          order_id: item.id,
          order_status: item.order_status
        }
      });
    },

    //支付
    selectPay(item) {
      //1现金，2余额，3积分
      let id = item.pay_order_id
        ? item.pay_order_id
        : this.$route.query.pay_order_id
        ? this.$route.query.pay_order_id
        : "";
      this.pay_order_id = id;
      let payway = item.payway
        ? Number.parseInt(item.payway)
        : item.pay_way
        ? Number.parseInt(item.pay_way)
        : 1;
      if (payway == 1 && this.type == 3) {
        payway = "";
      }
	  let wxTotal = Number.parseFloat(item.total_wx);
	  let intTotal = Number.parseFloat(item.total_integral);
    console.log("payway" + payway);
    if (payway === 5) {
      this.toShowCardPay();
    } else if(payway === 3&&(this.businessId == 2311||this.businessId == 2265)&&wxTotal == 0){
      this.$dialog.confirm({
        title: "请核对您的支付信息",
        mes: `您将消耗：${item.total_integral}积分
        </br>`,
        opts: [
          {
            txt: "取消",
            color: "#353535"
          },
          {
            txt: "确定",
            color: true,
            callback: async () => {
              this.payOrder(this.pay_order_id, '');
            }
          }
        ]
      });
      return;
    }else if (payway === 20) {
      this.is_pay = true;
    } else if (payway === 10 && wxTotal == 0 && intTotal > 0) {
        // 昆山存积分支付(直接弹窗确认形式)
      this.kunshanTxt = `该订单需要支付${intTotal}积分`;
      this.kunshan_toast = true;
    } else if (wxTotal == 0 && intTotal > 0) {
      if (!this.pay_password&&(this.businessId != 2311||this.businessId != 2265)) {
        //未设置支付密码
        this.no_pwd = true;
        return;
      } else if (this.pay_password) {
        //输入支付密码
        this.toPayPwd();
      }
    } else if (payway === 1 || payway === 10) {
      this.payOrder(id);
    } else if (payway === 11 || payway === 12) {
      this.jpkPay(payway); 
    } else {
      if (wxTotal > 0) {
          this.payOrder(id);
      } else {
          if (!this.pay_password&&(this.businessId != 2311||this.businessId != 2265)) {
            //未设置支付密码
            this.no_pwd = true;
            return;
          } else if (this.pay_password) {
            //输入支付密码
            this.toPayPwd();
          }
        }
	    }
    },

    //支付密码
    toPayPwd(val) {
      this.show_keyboard = true;
    },

    //立即支付
    async payOrder(id, val) {
      let bid = window.localStorage.getItem("bid");
      const self = this;
      this.kunshan_toast = false;
      try {
        if (id) {
          const res = await payOrder(id, val);
          if (res.code === 0) {
            const data = res.data;
            self.$dialog.loading.close();
            console.log({baseUrl})
              window.location.replace(
                `${baseUrl}/?bid=${bid}#/pay_success?id=${id}`
              );
          } else {
            self.$dialog.loading.close();
            self.$refs.keyboard.$emit("ydui.keyboard.error", res.msg);
          }
        }
      } catch (e) {}
    },

    //  验证支付密码
    checkPwd(val) {
      let self = this;
      self.$dialog.loading.open("验证支付密码");
      self.payOrder(self.pay_order_id, md5(val));
    },

    // 跳转订单详情
    orderDetail(item) {
      let order_status = this.getOrderId(this.getOrderKey(item.order_status));
      if (!this.no_click) {
        this.$router.push({
          path: "exchange_detail",
          query: {
            order_id: item.id,
            order_status: order_status
          }
        });
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "~@/common/less/variable.less";
@import "~@/common/less/mixin.less";

.recode {
  box-sizing: border-box;

  .list-top {
    display: flex;
    align-items: center;
    width: 100%;
    height: 0.88rem;
    background: #fff;
    position: fixed;
    top: 0;
    z-index: 999;

    .list-item {
      line-height: 0.88rem;
      .sc(0.28rem, #7d7d7d);
      text-align: center;
      flex: 1;
      position: relative;
    }

    .active {
      .sc(0.28rem, #333333);
      font-weight: bold;

      &::after {
        content: "";
        position: absolute;
        width: 0.5rem;
        height: 2px;
        background: var(--main-color);
        bottom: 0;
        left: 50%;
        margin-left: -0.25rem;
      }
    }
  }

  .list-cont {
    padding-top: 0.88rem;

    .list-layout {
      // height: 2.9rem;
      margin: 0.2rem;
      background: #fff;

      .overall-layout {
        margin: .2rem;
        padding: 0.2rem 0;
        height: 100%;

        .layout-top {
          width: 100%;
          .sc(0.32rem, #333333);
          background: white url(../../../assets/icon_detail@3x.png) no-repeat 100% 50%;
          background-size: 0.4rem 0.4rem;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .time {
            .sc(0.24rem, #999);
          }

          .status {
            .sc(0.24rem, var(--main-color));
          }
        }

        .layout-bottom {
          margin-top: 0.4rem;
          height: 1.2rem;
          position: relative;
          overflow: hidden;

          .img {
            float: left;

            img {
              .wh(0.96rem, 0.96rem)
            }
          }

          .use-point {
            float: right;
            padding-top: 0.09rem;
            .sc(0.28rem, #666);
          }

          .img-txt {
            .wh(3.4rem, 0.96rem);
            margin-left: 0.2rem;
            float: left;
            position: relative;   

            .number {
              .sc(0.24rem, #999);
              position: absolute;
              bottom: 0.05rem;
            }

            .name {
              .sc(0.28rem, #333);
              padding-top: 0.09rem;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }

        .order-btn {
          overflow: hidden;

          .common {
            float: right;
            margin-top: 0.2rem;
            width: 2rem;
            height: 0.68rem;
            border-radius: 0.68rem;
            text-align: center;
            line-height: 0.68rem;
            font-size: 0.28rem;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            background: #fff;
            color: var(--main-color);
            border: 1px solid var(--main-color);
            margin-right: 0.3rem;
          }

          .concel {
            color: #666;
          }
          
          .confirm {
            background:  var(--main-color);
            color: #fff;
          }
        }
      }
    }
  }
}
</style>